import { HALApiService } from '.'
import { createItemsData } from '../helpers/request-helpers'
import { printerUtils } from '../utils/printer-utils'
import { importService } from './import.service'
import { InstructionsService } from './instructions.service'
import { acceptanceService } from './requests/acceptance.service'
import { acceptanceServiceService } from "./requests/acceptance-service.service";
import { accountRateService } from './requests/account-rate.service'
import { accountsService } from './requests/accounts.service'
import { addressService } from './requests/address.service'
import { assemblingTasksService } from './requests/assembling-tasks.service'
import { Service } from './requests/base-service'
import { channelsService } from './requests/channels.service'
import { clientProfileService } from './requests/client-profile.service'
import { consumptionsService } from './requests/consumptions.service'
import { countriesService } from './requests/countries.service'
import { currenciesService } from './requests/currency.service'
import { deliveryRequestProcess, deliveryRequestsService } from './requests/delivery-requests.service'
import { deliveryServicesService } from './requests/delivery-services.service'
import { documentTemplatesService } from './requests/document-templates.service'
import { domainService } from './requests/domain.service'
import { eventsService } from './requests/events.service'
import { expectedOffersService } from './requests/expected-offers.service'
import { inputFilterService } from './requests/input-filter.service'
import { integrationImportService } from './requests/integration-import.service'
import { dsIntegrationsService } from './requests/integrations.service'
import { interactionsService } from './requests/interactions.service'
import { invoicesService } from './requests/invoice.service'
import { storageItemsService } from './requests/item-service.service'
import { legalEntitiesService } from './requests/legal-entities.service'
import { lengthsService } from './requests/length.service'
import { localitiesService } from './requests/locality.service'
import { locationLogService } from './requests/location-log.service'
import { messagesService } from './requests/messages.service'
import { notificationTasksService } from './requests/notification-tasts.service'
import { offersService } from './requests/offers.service'
import { ordersService } from './requests/orders.service'
import { paymentsService } from './requests/payments.service'
import { phonesService } from './requests/phones.service'
import { pickingSequenceService } from './requests/picking-sequences.service'
import { pickingTasksService } from './requests/picking-tasks.service'
import { dsPlaceService, dsPlaceItemsService } from './requests/place-item.service'
import { storagePlacesService } from './requests/place.service'
import { postcodesService } from './requests/postcodes.service'
import { dspTaskService } from './requests/preprocessing-tasks.service'
import { productsServiceService } from './requests/products.service'
import {assemblingQueueService, pickingQueueService, preprocessingQueueService} from './requests/queue.service'
import { RateModifierService } from './requests/rate-modifier.service'
import { dsRatesService } from './requests/rates.service'
import { reportService } from './requests/reports.service'
import { reserveService } from './requests/reserve.service'
import { rmCodeService } from './requests/rm-code.service'
import { userRolesService } from './requests/role.service'
import { senderService } from './requests/senders.service'
import { servicePointsService } from './requests/service-points.service'
import { shopsService } from './requests/shops.service'
import { sourceIntegrationService, IIntegrationService } from './requests/source-integrations.service'
import { sourceService } from './requests/source.service'
import { storageItemEntityService } from './requests/storage-items.service'
import { templatesService } from './requests/templates.service'
import { transactionsService } from './requests/transactions.service'
import { userService } from './requests/users.service'
import { warehouseService } from './requests/warehouse.service'
import { weightService } from './requests/weight.service'
import { eavService } from './requests/eav.service'
import { permissionsService } from './requests/permissions.service'
import utils from '../utils'
import { PermissionTypeService } from './requests/permission-types.service'
import { ProfileService } from './requests/profile.service'
import { DeliveryOrder } from './requests/delivery-order.service'
import { reportTypeService } from './requests/report-type.service'
import { categoryService } from './requests/category.service'
import { supplyService } from './requests/supply.service'
import { dictionaryService } from './requests/dictionary.service'
import { integrationCategoryService } from './requests/integration-category.service'
import { acceptanceItemsService } from './requests/acceptance-items.service'
import { helpSectionsService } from './requests/help-sections.service'
import { helpArticlesService } from './requests/help-article.service'
import { offerImportService } from './imports/offer.import'
import { shippingPlanService } from './requests/shipping-plan.service'
import {storageInventoryDocumentService} from './requests/storage-movement-inventory.service'
import {storageTasksFinishedLocationService} from "./requests/storage-tasks-finished-location.services";
import {deliveryServicesMovementService} from "./requests/delivery-services-movement.service";
import {storageOfferInventoryService} from './requests/storage-offer-inventory.service'
import {storageShopsInventoryService} from "@/apps/app/services/requests/storage-shops-inventory.service";
import {storageWarehousesInventoryService} from "@/apps/app/services/requests/storage-warehouses-inventory.service";
import {deliveryServicesRequestService} from "./requests/delivery-services-request.service";
import {automationRulesService} from "./requests/automation-rules.service";
import {warehouseServiceService} from "@/apps/app/services/requests/warehouse-service.service";

function getUrl (api) {
  return api.startsWith('/api')
    ? api.slice(4)
    : api
}

export function createService (api) {
  return new Service(getUrl(api), HALApiService, createItemsData)
}

export default {
  createService,
  instructions: (...params) => new InstructionsService(...params),
  httpClient: HALApiService,
  notificationChannel: channelsService,
  notificationInteraction: interactionsService,
  notificationEvent: eventsService,
  notificationTask: notificationTasksService,
  notificationTemplate: templatesService,
  deliveryServiceConsumption: consumptionsService,
  deliveryService: deliveryServicesService,
  deliveryRequest: deliveryRequestsService,
  deliveryRequestProcess: deliveryRequestProcess,
  acceptance: acceptanceService,
  storageItem: storageItemsService,
  import: importService,
  imports: {
    offer: offerImportService
  },
  printer: printerUtils,
  accountRate: accountRateService,
  accounts: accountsService,
  address: addressService,
  assemblingTask: assemblingTasksService,
  clientProfile: clientProfileService,
  country: countriesService,
  currency: currenciesService,
  documentTemplate: documentTemplatesService,
  expectedOffer: expectedOffersService,
  deliveryServiceIntegration: dsIntegrationsService,
  invoice: invoicesService,
  legalEntity: legalEntitiesService,
  length: lengthsService,
  locality: localitiesService,
  message: messagesService,
  offer: offersService,
  order: ordersService,
  payment: paymentsService,
  phone: phonesService,
  pickingSequence: pickingSequenceService,
  pickingTask: pickingTasksService,
  deliveryServicePlace: dsPlaceService,
  deliveryServicePlaceItems: dsPlaceItemsService,
  storagePlace: storagePlacesService,
  postcode: postcodesService,
  deliveryServicePreprocessingTask: dspTaskService,
  pickingQueue: pickingQueueService,
  preprocessingQueue: preprocessingQueueService,
  assemblingQueue: assemblingQueueService,
  rateModifier: new RateModifierService(HALApiService, createItemsData, utils),
  deliveryServiceRate: dsRatesService,
  sender: senderService,
  servicePoint: servicePointsService,
  shop: shopsService,
  iIntegration: sourceIntegrationService,
  iSource: sourceService,
  storageItemEntity: storageItemEntityService,
  transaction: transactionsService,
  user: userService,
  warehouse: warehouseService,
  weight: weightService,
  productsService: productsServiceService,
  acceptanceService: acceptanceServiceService,
  warehouseService: warehouseServiceService,
  deliveryServicesRequestService: deliveryServicesRequestService,
  userRoles: userRolesService,
  integrationImport: integrationImportService,
  iIntegrationService: IIntegrationService,
  reserve: reserveService,
  inputFilter: inputFilterService,
  reportType: reportTypeService,
  RMCode: rmCodeService,
  domain: domainService,
  report: reportService,
  locationLog: locationLogService,
  eav: eavService,
  permission: permissionsService,
  permissionType: new PermissionTypeService(),
  profile: new ProfileService(senderService, addressService, clientProfileService),
  deliveryOrder: new DeliveryOrder(ordersService, deliveryRequestsService, addressService, shopsService),
  category: categoryService,
  supply: supplyService,
  dictionary: dictionaryService,
  integrationCategory: integrationCategoryService,
  acceptanceItem: acceptanceItemsService,
  helpSection: helpSectionsService,
  helpArticle: helpArticlesService,
  shippingPlan: shippingPlanService,
  storageInventoryDocuments : storageInventoryDocumentService,
  movement : deliveryServicesMovementService,
  offerInventory: storageOfferInventoryService,
  shopsInventory: storageShopsInventoryService,
  warehousesInventory: storageWarehousesInventoryService,
  automationRules: automationRulesService,
  storageTasksFinishedLocation: storageTasksFinishedLocationService
}
