import _ from 'lodash'

/**
 * Convert Embedded
 *
 * @param {Object} object
 * @param {Array} ignoredFields
 * @param {Boolean} deep
 *
 * @returns {Object}
 */
export function convertEmbedded (object, ignoredFields = [], deep = true) {
  let obj = _.cloneDeep(object)

  if (obj._embedded) {
    Object.keys(obj._embedded)
      .forEach(val => {
        if (ignoredFields.includes(val) && obj._embedded[val] && !obj._embedded[val].id) {
          delete obj._embedded[val]
          return
        }

        if (obj._embedded[val] && obj._embedded[val].id) {
          obj[val] = obj._embedded[val].id
        } else {
          obj[val] = obj._embedded[val]
        }

        if (obj._embedded[val] && obj[val]._embedded) {
          obj[val] = convertEmbedded(obj[val])
        }

        if (!deep && obj[val] && obj[val]._embedded) {
          delete obj[val]._embedded
        }

        if (obj[val] && typeof obj[val] === 'object' && Object.keys(obj[val]).length === 0) {
          delete obj[val]
        }

        delete obj._embedded[val]
      })

    delete obj._embedded
  }

  return obj
}

/**
 * Create New Order Query
 *
 * @param {Object} data
 * @param {Array} products
 * @param {Object} deliveryRequest
 *
 * @returns {Object}
 */
export function createNewOrderData (order, products, deliveryRequest) {
  let data = _.cloneDeep(order)

  if (data._embedded.warehouse) {
    data.eav['order-reserve-warehouse'] = data._embedded.warehouse.id
  }
  
  delete data._embedded.warehouse

  if (deliveryRequest) {
    const convertedData = convertDeliveryRequest(deliveryRequest)

    if (convertedData.shipmentDate) {
      delete convertedData.shipmentDate
    }

    if (convertedData.rate || (convertedData.trackingNumber && convertedData.sender)) {
      if (convertedData.extId) {
        delete convertedData.extId
      }

      data.deliveryRequest = convertedData
    }
  }

  data.orderPrice = products.reduce((acc, val) => {
    return acc + val.price * val.count
  }, 0)

  data.totalPrice = data.orderPrice

  let covertedData = convertEmbedded(data)

  if (typeof covertedData.phone === 'object') {
    covertedData.phone = covertedData.phone.phone
  }

  covertedData.orderProducts = products.map(product => {
    const item = convertEmbedded(product)

    if (!item.shop) {
      item.shop = data.shop
    }

    item.payment = item.price
    return item
  })

  return covertedData
}

/**
 * Convert Delivery Request
 *
 * @param {Object} deliveryRequest
 *
 * @return {Object}
 */
export function convertDeliveryRequest (deliveryRequest) {
  const data = convertEmbedded(deliveryRequest)
  return Object.keys(data)
    .reduce((acc, key) => {
      return typeof data[key] === 'string' || typeof data[key] === 'number'
        ? { ...acc, [key]: data[key] }
        : acc
    }, {})
}

/**
 * Extract Changes between Objects
 * @param {Object} obj
 * @param {Object} b
 *
 * @returns {Object}
 */
export function difference (obj, b) {
  let object = _.cloneDeep(obj)
  let base = _.cloneDeep(b)
  return _.transform(object, (result, value, key) => {
    if (!_.isEqual(value, base[key])) {
      result[key] = _.isObject(value) && _.isObject(base[key])
        ? difference(value, base[key])
        : value
    }
  })
}

/**
 * Create Places Query
 *
 * @param {Number} id
 *
 * @returns {Object}
 */
export function createPlacesQuery (id) {
  return {
    draw: 5,
    page: 1,
    per_page: 50,

    sort: { id: 'desc' },

    'order-by': [
      { type: 'field', field: 'id', direction: 'desc' }
    ],

    filter: [
      { type: 'eq', field: 'state', value: 'active' },
      { type: 'eq', field: 'deliveryRequest', value: id }
    ],

    criteria: { state: 'active', deliveryRequest: id }
  }
}

/**
 * Create Packaging Data
 *
 * @param {Number} weight
 * @param {Object} dimensions
 * @param {Object} settings
 * @param {Object} deliveryRequest
 * @param {Object} queue
 *
 * @returns {Object}
 */
export function createPackagingData (weight, dimensions, settings = {}, deliveryRequest, queue) {
  const updateData = {
    state: 'measured',
    deliveryRequest: {
      weight: Number(weight),
      dimensions: Object.keys(dimensions).reduce((acc, key) => {
        acc[key] = Number(dimensions[key])
        return acc
      }, {}),
      id: deliveryRequest.id
    },
    queue: queue.id
  }

  const today = new Date()
  const tomorrow = new Date()

  tomorrow.setDate(today.getDate() + 1)

  if (settings.default && settings.default.sendDate) {
    const sendDate = new Date(settings.default.sendDate)

    updateData.deliveryRequest.sendDate = sendDate > today
      ? sendDate.toDateString()
      : tomorrow.toDateString()
  } else if (deliveryRequest) {
    if (deliveryRequest.sendDate) {
      const sendDate = new Date(deliveryRequest.sendDate.date)

      if (sendDate < today) {
        updateData.deliveryRequest.sendDate = tomorrow.toDateString()
      }
    }
  }

  if (settings.consumption) {
    updateData.deliveryRequest.shippedByDocument = settings.consumption.id
  }

  return updateData
}

/**
 * Create Packaging States
 *
 * @param {Boolean} acceptanceControl
 *
 * @returns {Array}
 */
export function createPackagingStates (acceptanceControl) {
  const states = ['pre_processing', 'error', 'sent']

  if (!acceptanceControl) {
    states.push('pending')
  }

  return states
}

/**
 * Create createDSRequest Result
 *
 * @param {Array} data
 *
 * @returns {Object}
 */
export function createDSRequestResult (data) {
  return data.reduce((acc, val) => {
    Object.keys(val).forEach(key => {
      if (val[key]) {
        acc[key].push(val[key])
      }
    })

    return acc
  }, { deliveryRequest: [], task: [], queue: [] })
}

/**
 * Create Delivery Request Query
 *
 * @param {Object} deliveryRequest
 * @param {Object} cleanDeliveryRequest
 * @param {Object} order
 * @param {Array} items
 *
 * @returns {Object}
 */
export function createDeliveryRequestData (deliveryRequest, cleanDeliveryRequest, order, items) {
  let data = _.cloneDeep(deliveryRequest)

  if (data.totalSum !== undefined) {
    delete data.totalSum
  }

  if (
    !data._embedded.recipientLocality &&
    data._embedded.recipientAddress &&
    data._embedded.recipientAddress._embedded &&
    data._embedded.recipientAddress._embedded.locality
  ) {
    data._embedded.recipientLocality = data._embedded.recipientAddress._embedded.locality

    delete data._embedded.recipientAddress._embedded.locality
  }

  if (data._embedded.recipientPhone && data._embedded.recipientPhone.phone) {
    if (cleanDeliveryRequest) {
      if (data?._embedded?.recipientPhone?.phone && ((cleanDeliveryRequest?._embedded?.recipientPhone?.phone && data._embedded.recipientPhone.phone !== cleanDeliveryRequest._embedded.recipientPhone.phone) || !cleanDeliveryRequest?._embedded?.recipientPhone?.phone)) {
        data.recipientPhone = data._embedded.recipientPhone.phone
      }
    } else if (data._embedded.recipientPhone.phone) {
      data.recipientPhone = data._embedded.recipientPhone.phone
    }

    delete data._embedded.recipientPhone
  }

  if (deliveryRequest.id) {
    const ignoredFields = [
      'currency',
      'deliveryService',
      'integration',
      'rate',
      'recipientLocality',
      'servicePoint',
      'sender'
    ]

    const diff = convertEmbedded(difference(data, cleanDeliveryRequest), ignoredFields, false)
    console.debug({ diff: { ...diff }, data: { ...data }, cleanDeliveryRequest: {...cleanDeliveryRequest}, dr: { ...deliveryRequest } })

    if (diff.places) {
      delete diff.places
    }

    if(diff.dimensions && deliveryRequest.dimensions && !diff.weight) {
      diff.dimensions = {
        ...deliveryRequest.dimensions,
        ...diff.dimensions
      }
      diff.weight =  deliveryRequest.weight
    } else if (diff.weight && deliveryRequest.weight  && !diff.dimensions ) {
      diff.dimensions = {
        ...deliveryRequest.dimensions,
      }
    } else if (diff.dimensions && deliveryRequest.dimensions && diff.weight && deliveryRequest.weight) {
      diff.dimensions = {
        ...deliveryRequest.dimensions,
        ...diff.dimensions
      }
    }

    if (diff.recipientAddress && diff.recipientAddress.owner) {
      delete diff.recipientAddress.owner
    }

    if (diff.recipientAddress && diff.recipientAddress.locality) {
      delete diff.recipientAddress.locality
    }

    if (diff.recipientAddress) {
      if (diff.recipientAddress.updated) {
        delete diff.recipientAddress.updated
      }

      if (Object.keys(diff.recipientAddress).length === 0) {
        delete diff.recipientAddress
      }
    }

    if (Object.keys(diff).length > 0 && data.state === 'pending_error') {
      diff.state = 'pending_queued'
    }

    if (order) {
      delete diff.payment

      if (order.paymentState === 'not_paid') {
        delete diff.estimatedCost
      }
    }

    if (!order && data.eav['delivery-request-products-order']) {
      delete diff.payment
      delete diff.estimatedCost
    }

    if (diff.servicePoint === null && !cleanDeliveryRequest._embedded.servicePoint) {
      delete diff.servicePoint
    }

    return diff
  }

  data = convertEmbedded(data)

  if (data.recipientAddress && data.recipientAddress.locality) {
    delete data.recipientAddress.locality
  }

  if (data.recipientAddress && data.recipientAddress.owner) {
    delete data.recipientAddress.owner
  }

  if (items.length === 0) {
    if (data.places) {
      data.places = data.places.map(({ items }) => ({ items }))
    }

    return data
  }

  const itemFields = ['extId', 'name', 'dimensions', 'weight', 'tax']
  const place = {
    items: items.reduce((acc, item) => {
      const offer = item._embedded.productOffer

      const product = itemFields.reduce((pr, field) => {
        pr[field] = offer[field] !== undefined
          ? offer[field]
          : null

        return pr
      }, {})

      product.payment = order.paymentState === 'paid'
        ? 0
        : offer.price

      product.estimatedCost = offer.price
      product.count = Number(item.count)
      product.dimensions = null
      product.weight = null

      acc.push(product)

      return acc
    }, [])
  }

  data.places = [place]

  data.eav = {
    'delivery-request-products-order': order.id
  }

  data.order = order.id

  return data
}

/**
 * Create Account Statistics Data
 *
 * @param {Array} items
 *
 * @returns {Array}
 */
export function createAccountStatisticsData (items) {
  return items.reduce((acc, val) => {
    const account = acc.find(ac => ac.id === val.account_id)

    if (account) {
      account.balance += parseFloat(val.sumvalue)
      account.modifiers.push(val)
    } else {
      acc.push({
        id: val.account_id,
        type: val.accounttype,
        balance: parseFloat(val.sumvalue),
        modifiers: [val]
      })
    }

    return acc
  }, [])
}

/**
 * Create Date
 *
 * @param {String | Number} value
 *
 * @returns {String}
 */
export function createDate (value) {
  if (!value) {
    return value
  }

  const date = new Date(value)
  const month = date.getMonth() + 1
  const day = date.getDate()
  return date.getFullYear() + '-' + (month < 10 ? `0${month}` : month) + '-' + (day < 10 ? `0${day}` : day)
}

/**
 * Create Items Data
 *
 * @param {Object} data
 *
 * @returns {Object}
 */
export function createItemsData (data) {
  const keys = Object.keys(data._embedded)

  return {
    items: data._embedded[keys[0]],
    totalItems: data.total_items,
    page: data.page,
    totalPages: data.page_count
  }
}

/**
 * Merge Query
 *
 * @param {Object | Undefined} firstQ
 * @param {*} secondQ
 *
 * @returns {Object}
 */
export function mergeQuery (firstQ, secondQ) {
  if (!secondQ && firstQ) {
    firstQ.filter = (firstQ.filter || []).reduce((acc, x) => {
      if (x.type === 'in' && x.values.length === 0) {
        return acc
      }

      acc.push(x)
      return acc
    }, [])

    return firstQ
  }

  if (!firstQ && secondQ) {
    return secondQ
  }

  if (!firstQ && !secondQ) {
    return {}
  }

  const query = _.cloneDeep(firstQ)
  const secondQuery = _.cloneDeep(secondQ)

  if (!query.filter) {
    query.filter = []
  }

  if (!secondQuery.filter) {
    secondQuery.filter = []
  }

  const filter = (query.filter || [])
    .reduce((acc, x) => {
      if (x.type === 'in' && x.values.length === 0) {
        return acc
      }

      if (secondQuery.filter.find(({ field }) => field === x.field)) {
        return acc
      }

      acc.push(x)
      return acc
    }, [])

  query.filter = [
    ...secondQuery.filter || [],
    ...filter
  ]

  return query
}

/**
 * Create Geo
 *
 * @param {Any} value
 * @param {Any} defaultCoords
 *
 * @returns {Array}
 */
export function createGeo (value, defaultCoords = window.appOptions.defaultCoords || [43.204666, 27.910543]) {
  if (!value) {
    console.error('Geo is empty!')
    return Array.isArray(defaultCoords)
      ? defaultCoords
      : createGeo(defaultCoords)
  }

  if (Array.isArray(value)) {
    return value
  }

  if (typeof value === 'object') {
    return [value.latitude, value.longitude]
  }

  if (value[0] === '{' && value[value.length - 1] === '}') {
    return createGeo(JSON.parse(value))
  }

  const indexOfOpen = value.indexOf('(') + 1
  const indexOfClose = value.indexOf(')')

  return value.slice(indexOfOpen, indexOfClose).split(' ').map(Number).reverse()
}
