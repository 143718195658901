import { createDSRequestResult, createPackagingData, createPackagingStates } from './../../../helpers/request-helpers'

import services from './../../../services/services'
import { DSRequestService } from './../../../services/delivery-services'

export default {
  state: {},
  getters: {},
  mutations: {
    setMode (state, payload) {
      state.mode = payload
    },
    setScanType (state, payload) {
      state.scanType = payload
    },
    setDimensions (state, { width, height, length, weight }) {
      state = {
        ...state,
        width,
        height,
        length,
        weight
      }
    },
    updatePackaging (state, payload = {}) {
      Object.keys(payload).forEach(key => {
        state[key] = payload[key]
      })
    },
    resetDimensions (state) {
      state = {
        ...state,
        width: null,
        height: null,
        length: null,
        weight: null
      }
    },
    setQueue (state, payload) {
      state.queue = payload
    },
    setPrinter (state, payload) {
      state.printer = payload
    },
    setWidth (state, payload) {
      state.width = payload
    },
    setHeight (state, payload) {
      state.height = payload
    },
    setLength (state, payload) {
      state.length = payload
    },
    setWeight (state, payload) {
      state.weight = payload
    },
    setSettings (state, payload) {
      state.settings = payload
    },
    updateSettings (state, payload) {
      state.settings = {
        ...state.settings,
        ...payload
      }

      const appOptions = {
        ...window.appOptions,
        packagingSettings: state.settings
      }

      window.appOptions = appOptions
      localStorage.setItem('appOptions', JSON.stringify(appOptions))
    },
    updateSettingsDefault (state, payload) {
      state.settings.default = {
        ...state.settings.default,
        ...payload
      }

      const appOptions = {
        ...window.appOptions,
        packagingSettings: state.settings
      }

      window.appOptions = appOptions
      localStorage.setItem('appOptions', JSON.stringify(appOptions))
    },
    setInterceptAutoClose (state, payload) {
      state.settings.printing.interceptAutoClose = payload

      const appOptions = {
        ...window.appOptions,
        packagingSettings: state.settings
      }

      window.appOptions = appOptions
      localStorage.setItem('appOptions', JSON.stringify(appOptions))
    }
  },
  actions: {
    getBoxLabels ({ state }, { id, mode, noAutoPrint }) {
      return services.deliveryServicePlace.getLabel(id)
        .then(result => {
          const settings = { ...state.settings.printing }

          // Only if mode is show only then force modal else use modal setting
          if (mode === 'show') {
            settings.modal = mode === 'show'
          }

          if (noAutoPrint) {
            services.printer.createWindow(result.text)
          } else {
            services.printer.print(result.text, result.pageSize)
          }
        })
    },
    getLabels ({ state }, payload) {
      let id = payload.id
      let mode = payload.mode
      let noAutoPrint = payload.noAutoPrint
      let accept = payload.accept || 'application/pdf,text/html'

      return services.deliveryServicePreprocessingTask.getLabel(id, accept)
        .then(result => {
          console.log(result)
          const settings = { ...state.settings.printing }

          // Only if mode is show only then force modal else use modal setting
          if (mode === 'show') {
            settings.modal = mode === 'show'
          }

          if (noAutoPrint) {
            services.printer.createWindow(result.text)
          } else {
            services.printer.print(result.text, result.pageSize)
          }
        })
    },
    printLabel ({ state, dispatch, commit }, payload = {}) {
      const task = payload.task || state.task
      let accept = payload.accept || 'application/pdf,text/html'

      Promise.resolve(task)
        .then(hasTask => {
          return hasTask
            ? Promise.resolve(task)
            : services.deliveryServicePreprocessingTask.getByDeliveryRequest(state.deliveryRequest.id, accept)
        })
        .then(task => {
          if (!task) {
            commit('addErrorNotification', 'Task is not found!')
            return
          }

          dispatch('getLabels', { id: task.id, ...payload })
        })
    },
    printBoxLabel ({ state, dispatch, commit }, payload = {}) {
      const mode = payload.mode || state.mode
      const place = payload.place || state.place
      console.log(123)

      Promise.resolve(place)
        .then(place => {
          if (!place) {
            commit('addErrorNotification', 'Place is not found!')
            return
          }

          dispatch('getBoxLabels', { id: place.id, mode })
        })
    },
    measuring ({ commit, state, dispatch }, { barcode, middleware }) {
      const { trustIncomingMeasurements } = state.settings

      const actions = {
        X: (barcode) => {
          commit('setWidth', barcode.value)
        },
        Y: (barcode) => {
          commit('setHeight', barcode.value)
        },
        Z: (barcode) => {
          commit('setLength', barcode.value)
        },
        DB: (barcode) => {
          commit('setWidth', barcode.value[1])
          commit('setHeight', barcode.value[2])
          commit('setLength', barcode.value[3])
          commit('setWeight', barcode.value[0])

          if (trustIncomingMeasurements && middleware) {
            middleware()
              .then(_ => {
                dispatch('executeForm')
              })
              .catch(error => {
                commit('addErrorNotification', error)
              })
          }
        },
        execute: () => {
          if (middleware) {
            middleware()
              .then(_ => {
                dispatch('executeForm')
              })
              .catch(error => {
                commit('addErrorNotification', '123' + error)
              })
          } else {
            dispatch('executeForm')
          }
        }
      }

      if (typeof actions[barcode.type] === 'function') {
        actions[barcode.type](barcode)
      } else if (typeof barcode.value === 'string' && barcode.value.match(/[\d+]/g)) {
        commit('setWeight', barcode.value)
      }

      if (barcode.type === 'execute') {
        return
      }

      if (middleware) {
        middleware()
          .then(_ => {
            dispatch('executeForm')
          })
          .catch(error => {
            commit('addErrorNotification', error)
          })
      }
    },
    measuringByDefaultValues ({ commit, state, dispatch }) {
      const { trustIncomingMeasurements, setPredefinedMeasurements, immediatelySubmit } = state.settings

      commit('setScanType', 'measurements')

      let params = {
        weight: 0,
        width: 0,
        height: 0,
        length: 0
      }

      if (trustIncomingMeasurements) {
        params = {
          weight: Number(state.deliveryRequest.weight),
          width: state.deliveryRequest.dimensions.x,
          height: state.deliveryRequest.dimensions.y,
          length: state.deliveryRequest.dimensions.z
        }
      }

      if (setPredefinedMeasurements) {
        params = state.settings.default
      }

      const keys = ['weight', 'width', 'height', 'length']

      const update = Object.keys(params)
        .reduce((acc, key) => {
          if (keys.includes(key) && params[key] > 0) {
            acc[key] = params[key]
          }

          return acc
        }, {})

      commit('updatePackaging', update)

      if (!Object.values(params).find(value => value < 1) && immediatelySubmit) {
        dispatch('executeForm')
      }
    },
    packaging ({ commit }, { deliveryRequest, task, queue }) {
      const updatedData = {
        state: 'packed',
        deliveryRequest: {
          id: deliveryRequest.id
        },
        queue: queue.id
      }

      const id = task
        ? task.id
        : undefined

      return services.deliveryServicePreprocessingTask.process(id, updatedData)
        .then(result => {
          services.printer.print(result.text, result.pageSize)
          commit('addNotification', 'Successful saved!')
        })
    },
    measured ({ commit }, { deliveryRequest, task, queue }) {
      const { weight, dimensions } = deliveryRequest
      const { x, y, z } = dimensions

      const data = {
        state: 'measured',
        deliveryRequest: {
          weight,
          width: x,
          height: y,
          length: z,
          id: deliveryRequest.id
        },
        queue: queue.id
      }

      const id = task
        ? task.id
        : undefined

      return services.deliveryServicePreprocessingTask.process(id, data)
        .then(result => {
          commit('addNotification', 'Successful saved!')
          return services.printer.print(result.text, result.pageSize)
        })
    },
    loadDeliveryRequestByBarcode ({ commit, state, dispatch }, payload, hasQueue = false) {
      const { acceptanceControl } = state.settings
      const states = createPackagingStates(acceptanceControl)
      const method = payload.type === 'P/O'
        ? 'searchByLinkedOrderId'
        : 'searchByDeliveryRequestId'

      return DSRequestService[method](payload.value, states)
        .then(data => {
          if (data.length === 0) {
            commit('addErrorNotification', 'Requests not found')
            return
          }

          const result = createDSRequestResult(data)

          if (result.deliveryRequest.length > 0) {
            if (result.deliveryRequest.length > 1) {
              commit('addWarningNotification', result.deliveryRequest.length + ' requests found')
              return
            }

            commit('addWarningNotification', 'Request was loaded')

            if (!state.deliveryRequest || state.deliveryRequest.id !== result.deliveryRequest[0].id) {
              commit('setDeliveryRequest', result.deliveryRequest[0])
            }

            commit('setTask', result.task[0])
            if (!hasQueue) {
              commit('setQueue', result.queue[0])
            }
            commit('setScanType', 'measurements')

            const modeActions = {
              measuring () {
                dispatch('measuringByDefaultValues')
              },
              packaging () {
                const { deliveryRequest, task, queue } = result
                commit('setScanType', 'delivery-request')
                dispatch('packaging', { deliveryRequest: deliveryRequest[0], task: task[0], queue: queue[0] })
              },
              measured () {
                const { task, queue } = result
                const { deliveryRequest } = state

                if (!queue[0]) {
                  commit('addErrorNotification', 'Queue is not found!')
                  return
                }

                dispatch('measured', { deliveryRequest: deliveryRequest, task: task[0], queue: queue[0] })
              }
            }

            modeActions[state.mode]()
          }
        })
    },
    executeForm ({ commit, state }) {
      const { mode, weight, width, height, length, settings, deliveryRequest, task, queue } = state
      const check = weight > 0 && width > 0 && height > 0 && length > 0

      if (mode !== 'measuring' || !check) {
        return
      }

      const updateData = createPackagingData(weight, {
        x: width,
        y: height,
        z: length
      }, settings, deliveryRequest, queue)

      commit('addWarningNotification', 'Task start executing.')

      const id = task
        ? task.id
        : undefined

      return services.deliveryServicePreprocessingTask.process(id, updateData)
        .then(result => {
          commit('addNotification', 'Task is successful executed. Now it will be printed.')
          commit('setScanType', 'delivery-request')
          services.printer.print(result.text, result.pageSize)
        })
        .catch((err) => {
          const validStatusCodes = [503, 409, 423]

          if (validStatusCodes.includes(err.status)) {
            return Promise.reject(err)
          } else {
            commit('setScanType', 'delivery-request')
          }
        })
    }
  },
  executeDRPlace ({ commit, state }) {
    const { weight, width, height, length } = state
    const check = weight > 0 && width > 0 && height > 0 && length > 0

    if (!check) {
      return
    }

    commit('addWarningNotification', 'Task start executing.')

    return Promise.resolve()
  }
}
