import _ from 'lodash'
import { router } from './../../../router'

import services from '../../../services/services'

import { createDeliveryRequestData, createPlacesQuery, mergeQuery } from './../../../helpers/request-helpers'

export default {
  state: {
    deliveryRequests: [],
    lockedDeliveryRequests: [],
    deliveryRequestsLoading: false,
    deliveryRequestsItemsNumber: 0,
    deliveryRequestsPage: 1,
    deliveryRequestsTotalPages: 1,
    deliveryRequest: null,
    cleanDeliveryRequest: null,
    lastDRIndex: -1,
    hasDRChange: false
  },
  getters: {
    hasDRChange: state => state.hasDRChange,
    deliveryRequests: state => state.deliveryRequests,
    lockedDeliveryRequests: state => state.lockedDeliveryRequests,
    deliveryRequestsLoading: state => state.deliveryRequestsLoading,
    deliveryRequestsItemsNumber: state => state.deliveryRequestsItemsNumber,
    deliveryRequestsPage: state => state.deliveryRequestsPage,
    deliveryRequestsTotalPages: state => state.deliveryRequestsTotalPages,
    deliveryRequest: state => {
      if (!state.deliveryRequest) {
        return state.deliveryRequest
      }

      return {
        ...state.deliveryRequest,
        totalSum: Number(state.deliveryRequest.estimatedCost || 0) + Number(state.deliveryRequest.retailPrice || 0)
      }
    },
    cleanDeliveryRequest: state => state.cleanDeliveryRequest,
    lastDRIndex: state => state.lastDRIndex
  },
  mutations: {
    setDeliveryRequestsData (state, { items, page, totalPages, totalItems }) {
      state.deliveryRequests = items
      state.deliveryRequestsItemsNumber = totalItems
      state.deliveryRequestsPage = page
      state.deliveryRequestsTotalPages = totalPages
      state.lastDRIndex = -1
    },
    setDeliveryRequests (state, payload) {
      state.deliveryRequests = payload
    },
    setDeliveryRequestsItemsNumber (state, payload) {
      state.deliveryRequestsItemsNumber = payload
    },
    setDeliveryRequestsPage (state, payload) {
      state.deliveryRequestsPage = payload
    },
    setDeliveryRequestsTotalPages (state, payload) {
      state.deliveryRequestsTotalPages = payload
    },
    setDeliveryRequest (state, payload) {
      if (!payload) {
        state.deliveryRequest = null
        state.cleanDeliveryRequest = null
        state.places = []
        return
      }

      const data = _.cloneDeep(payload)

      if (data._embedded) {
        if (data._embedded.recipientAddress) {
          data._embedded.recipientAddress = {
            ...data._embedded.recipientAddress,
            _embedded: {}
          }

          if (!data._embedded.recipientAddress._embedded) {
            data._embedded.recipientAddress._embedded = {}
          }

          data._embedded.recipientAddress._embedded.locality = data._embedded.recipientLocality
        } else {
          data._embedded.recipientAddress = {
            postcode: null,
            house: null,
            notFormal: null,
            street: null,
            _embedded: {
              locality: null
            }
          }
        }
      }

      state.deliveryRequest = data
      if (data.places) {
        state.places = _.cloneDeep(data.places)
        delete data.places
      }

      state.cleanDeliveryRequest = _.cloneDeep(data)
    },
    setCleanDeliveryRequest (state, payload) {
      state.cleanDeliveryRequest = payload
    },
    updateCleanDeliveryRequest (state, payload) {
      state.cleanDeliveryRequest = { ...state.cleanDeliveryRequest, ...payload }
    },
    updateDeliveryRequest (state, payload) {
      state.deliveryRequest = {
        ...state.deliveryRequest,
        ...payload
      }
      state.hasDRChange = true
    },
    updateDeliveryRequestEmbedded (state, payload) {
      state.deliveryRequest._embedded = {
        ...state.deliveryRequest._embedded,
        ...payload
      }
      state.hasDRChange = true
    },
    setHasDRChange (state, payload) {
      state.hasDRChange = payload
    },
    setDeliveryRequestFromList (state, payload) {
      state.deliveryRequests = state.deliveryRequests.map(request => {
        return Number(request.id) === Number(payload.id)
          ? payload
          : request
      })
    },
    resetDeliveryRequest (state) {
      state.deliveryRequest = null
      state.cleanDeliveryRequest = null
      state.history = []
      state.places = []
    },
    setNewDeliveryRequest (state) {
      state.deliveryRequest = {
        deliveryDate: null,
        sendDate: new Date().toDateString(),
        places: [],
        weight: null,
        eav: {},
        dimensions: null,
        _embedded: {
          sender: null,
          rate: null,
          recipient: {
            email: null,
            name: null
          },
          recipientLocality: null,
          recipientAddress: {
            postcode: null,
            house: null,
            notFormal: null,
            street: null,
            _embedded: {
              locality: null
            }
          }
        }
      }
    },
    setLastDRIndex (state, payload) {
      state.lastDRIndex = payload
    },
    startRequestsLoading (state) {
      state.deliveryRequestsLoading = true
    },
    stopRequestsLoading (state) {
      state.deliveryRequestsLoading = false
    },
    addLockedDeliveryRequest (state, payload) {
      if (state.lockedDeliveryRequests.find(id => id === payload)) {
        return
      }

      state.lockedDeliveryRequests = [...state.lockedDeliveryRequests, payload]
    },
    removeLockedDeliveryRequest (state, payload) {
      state.lockedDeliveryRequests = state.lockedDeliveryRequests.filter(id => id !== payload)
    },
    upsertDeliveryRequest (state, payload) {
      let isExist = false

      state.deliveryRequests = state.deliveryRequests.map(x => {
        if (x.id === payload.id) {
          isExist = true
          return payload
        }

        return x
      })

      if (!isExist) {
        state.deliveryRequests.unshift(payload)
      }
    }
  },
  actions: {
    loadDeliveryRequests ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.deliveryRequests)
      return services.deliveryRequest.getAll(query)
        .then(data => {
          commit('setDeliveryRequestsData', data)
          return data
        })
    },
    loadDeliveryRequest ({ commit }, payload) {
      return services.deliveryRequest.get(payload)
        .then(item => {
          commit('setDeliveryRequest', item)
          return item
        })
    },
    refreshDeliveryRequest ({ commit }, payload) {
      return services.deliveryRequest.get(payload)
        .then(item => {
          commit('updateDeliveryRequest', item)
          return item
        })
    },
    saveDeliveryRequest ({ commit, state }, payload) {
      const { deliveryRequest, order, orderProducts, cleanDeliveryRequest } = state

      const data = createDeliveryRequestData(deliveryRequest, cleanDeliveryRequest, order && _.cloneDeep(order), orderProducts)

      if (payload && payload.ignoreEstimatedCost) {
        delete data.estimatedCost
      }

      if (data.shipmentDate !== undefined) {
        delete data.shipmentDate
      }

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes in delivery request!')
        return Promise.resolve(deliveryRequest)
      }

      const header = state.deliveryRequest.id && state.deliveryRequest.updated
        ? { 'X-Entity-Updated': state.deliveryRequest.updated }
        : {}

      return services.deliveryRequest.save(data, deliveryRequest.id, header)
        .then(item => {
          let newDeliveryRequest = _.cloneDeep(item)
          if (state.deliveryRequest.places) {
            newDeliveryRequest.places = state.deliveryRequest.places
          }

          commit('setDeliveryRequest', newDeliveryRequest)

          if (payload && payload.redirectLink) {
            router.push(payload.redirectLink)
          }

          return item
        })
    },
    saveDeliveryRequestByPayload (_, payload) {
      return services.deliveryRequest.save(payload.data, payload.id, payload.header)
    },
    loadNextDeliveryRequest ({ state, commit, dispatch }) {
      if (state.lastDRIndex >= state.deliveryRequests.length) {
        if (state.deliveryRequestsPage === state.deliveryRequestsTotalPages) {
          commit('addWarningNotification', 'No more items!')
          return
        }

        commit('addWarningNotification', 'Loading next page with items.')

        const query = {
          per_page: 25,
          page: state.deliveryRequestsPage + 1,
          sort: {
            created: 'asc'
          },
          filter: [
            {
              type: 'in',
              field: 'state',
              values: ['pending', 'pre_processing']
            }
          ]
        }

        dispatch('loadDeliveryRequests', query)
        commit('setPlaces', [])
        return
      }

      const index = state.lastDRIndex + 1
      commit('setLastDRIndex', index)
      commit('setPlaces', [])

      const deliveryRequest = state.deliveryRequests[index]

      if (state.additionalContent) {
        const query = createPlacesQuery(deliveryRequest.id)

        dispatch('loadPlaces', query)
      }

      return Promise.resolve(deliveryRequest)
    }
  }
}
