import _ from 'lodash'
import services from '../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    acceptance: null,
    acceptanceItems: [],
    cleanAcceptanceItems: [],
    cleanAcceptance: null,
    acceptances: [],
    acceptancesLoading: false,
    totalAcceptanceNumber: 0,
    acceptancePage: 1,
    acceptanceTotalPages: 1,
    virtualShop: ''
  },
  getters: {
    acceptance: state => state.acceptance,
    acceptanceItems: state => state.acceptanceItems,
    cleanAcceptanceItems: state => state.cleanAcceptanceItems,
    cleanAcceptance: state => state.cleanAcceptance,
    acceptances: state => state.acceptances,
    acceptancesLoading: state => state.acceptancesLoading,
    totalAcceptanceNumber: state => state.totalAcceptanceNumber,
    acceptancePage: state => state.acceptancePage,
    acceptanceTotalPages: state => state.acceptanceTotalPages,
    virtualShop: state => state.virtualShop
  },
  mutations: {
    setAcceptance (state, payload) {
      state.acceptance = payload
      state.cleanAcceptance = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanAcceptance (state, payload) {
      state.cleanAcceptance = payload
    },
    setNewAcceptance (state) {
      state.acceptance = {
        type: 'products',
        extId: '',
        comment: '',
        extDate: new Date().toJSON().slice(0, 10).replace(/-/g, '/'),
        _embedded: {
          warehouse: null,
          deliveryRequest: null
        }
      }
    },
    updateCleanAcceptance (state, payload) {
      state.cleanAcceptance = {
        ...state.cleanAcceptance,
        ...payload
      }
    },
    updateAcceptance (state, payload) {
      state.acceptance = {
        ...state.acceptance,
        ...payload
      }
    },
    updateAcceptanceEmbedded (state, payload) {
      if (payload.deliveryRequest && state.acceptance._embedded.deliveryRequest) {
        if (payload.deliveryRequest._embedded && state.acceptance._embedded.deliveryRequest._embedded) {
          state.acceptance._embedded.deliveryRequest._embedded = {
            ...state.acceptance._embedded.deliveryRequest._embedded,
            ...payload.deliveryRequest._embedded
          }
        } else {
          state.acceptance._embedded.deliveryRequest = {
            ...state.acceptance._embedded.deliveryRequest,
            ...payload.deliveryRequest
          }
        }
      } else {
        state.acceptance._embedded = {
          ...state.acceptance._embedded,
          ...payload
        }
      }
    },
    setAcceptancesData (state, { items, page, totalPages, totalItems }) {
      state.acceptances = items
      state.totalAcceptanceNumber = totalItems
      state.acceptancePage = page
      state.acceptanceTotalPages = totalPages
    },
    setAcceptances (state, payload) {
      state.acceptances = payload
    },
    setTotalAcceptanceNumber (state, payload) {
      state.totalAcceptanceNumber = payload
    },
    setAcceptancesPage (state, payload) {
      state.acceptancePage = payload
    },
    setAcceptancesTotalPages (state, payload) {
      state.acceptanceTotalPages = payload
    },
    startAcceptanceLoading (state) {
      state.acceptancesLoading = true
    },
    stopAcceptanceLoading (state) {
      state.acceptancesLoading = false
    },
    setAcceptanceItems (state, payload) {
      console.log(payload)
      state.acceptanceItems = payload
    },
    setCleanAcceptanceItems (state, payload) {
      state.cleanAcceptanceItems = payload
    },
    upsertAcceptance (state, payload) {
      let isExist = false

      state.acceptances = state.acceptances.map(x => {
        if (x.id === payload.id) {
          isExist = true
          return payload
        }

        return x
      })

      if (!isExist) {
        state.acceptances.unshift(payload)
      }
    },
    setVirtualShop (state, payload) {
      state.virtualShop = payload
    }
  },
  actions: {
    loadVirtualShop ({ commit }, payload) {
      const arrayOfUrl = payload.split('/')
      const virtualShopId = arrayOfUrl[payload.split('/').length - 1]
      const url = `/products/shops/${virtualShopId}`

      return services.httpClient.get(url).then(data => {
        commit('setVirtualShop', data)
        return data
      })

    },
    loadAcceptances ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.acceptances)
      return services.acceptance.getAll(query)
        .then(data => {
          commit('setAcceptancesData', data)
          return data
        })
    },
    loadAcceptance ({ commit }, payload) {
      return services.acceptance.get(payload)
        .then(item => {
          commit('setAcceptance', item)
          return item
        })
    },
    loadAcceptanceItems ({ commit }, payload) {
      return services.acceptanceItem.getAll(payload)
        .then(data => {
          commit('setAcceptanceItems', data)
          return data
        })
    },
    downloadAcceptancePDF ({}, payload) {
      return services.acceptance.getPDF(payload.url)
        .then(data => {
          data.text().then(data => {
            const link = document.createElement('a')
            link.innerHTML = 'Download PDF file'
            link.download = 'Acceptance#' + payload.id + '.pdf'
            link.href = data
            link.click()
          })
        })
    },
    saveAcceptance ({ state }) {
      const ignoredFields = ['warehouse']
      const query = state.acceptance.id
        ? convertEmbedded(difference(state.acceptance, state.cleanAcceptance), ignoredFields)
        : convertEmbedded(state.acceptance)

      if (query.docExtended?.filter(function (keyword) {
        keyword !== null
      })) {
        query.docExtended = state.acceptance.docExtended
      }

      if (Object.keys(query).length === 0) {
        return Promise.resolve(state.acceptance)
      }

      const header = state.acceptance.id && state.acceptance.updated
        ? { 'X-Entity-Updated': state.acceptance.updated }
        : {}

      return services.acceptance.save(query, state.acceptance.id, header)
    }
  }
}
