const Apps = () => import('./../../pages/Apps.vue')
const App = () => import('./../../pages/App.vue')
const AppAmazon = () => import('./../../pages/apps/Amazon.vue')
const AppEtsy = () => import('./../../pages/apps/Etsy.vue')
const AppShopify = () => import('./../../pages/apps/Shopify.vue')
const ShippingPlans = () => import('./../../pages/wms/ShippingPlans.vue')
const ShippingPlan = () => import('./../../pages/wms/ShippingPlan.vue')
const DMIntegrations = () => import('../../pages/delivery-manager/DMIntegrations')
const Sources = () => import('../../pages/settings/Sources')
const DSIntegration = () => import('./../../components/integration-wizard/DSIntegration')
const SourceIntegration = () => import('../../components/integration-wizard/SourceIntegration')

export default [
    {
        entityClass: 'Orderadmin\\Integrations\\Entity\\Integration',
        path: '/apps',
        name: 'Apps',
        icon: 'apps',
        children: [
            {
                entityClass: 'Orderadmin\\Integrations\\Entity\\Integration',
                path: 'apps',
                name: 'Apps',
                noChild: true,
                component: Apps,
                children: [
                    {
                        path: 'entity/:id',
                        component: App
                    },
                    {
                        path: 'entity/amazon/:id',
                        component: AppAmazon
                    },
                    {
                        path: 'entity/etsy/:id',
                        component: AppEtsy
                    },
                    {
                        path: 'entity/shopify/:id',
                        component: AppShopify
                    },
                    {
                        path: 'amazon/shipping-plans',
                        component: ShippingPlans
                    },
                    {
                        path: 'amazon/shipping-plans/:id',
                        component: ShippingPlan
                    }
                ]
            },
            {
                entityClass: 'Orderadmin\\Integrations\\Entity\\Integration',
                path: 'shipping',
                name: 'Shipping carriers integrations',
                details: [
                    'Connect your shipping carriers to create your shipping labels.'
                ],
                component: DMIntegrations,
                options: {
                    query: {
                        per_page: 25,
                        page: 1,
                        'order-by': [
                            { type: 'field', field: 'created', direction: 'desc' }
                        ],
                        filter: [
                            { type: 'eq', field: 'state', value: 'active' }
                        ]
                    }
                },
                children: [
                    {
                        entityClass: 'Orderadmin\\Integrations\\Entity\\Integration',
                        path: 'entity',
                        component: DSIntegration
                    },
                    {
                        entityClass: 'Orderadmin\\Integrations\\Entity\\Integration',
                        path: 'entity/:id',
                        component: DSIntegration
                    }
                ]
            },
            {
                entityClass: 'Orderadmin\\Integrations\\Entity\\Integration',
                path: 'eccomerce',
                name: 'Ecommerce & Marketplace Integrations',
                details: [
                    'Connect your ecommerce platforms to sync your products and orders with Orderadmin.'
                ],
                component: Sources,
                options: {
                    query: {
                        per_page: 25,
                        page: 1,
                        'order-by': [
                            { type: 'field', field: 'created', direction: 'desc' }
                        ],
                        filter: [
                            { type: 'eq', field: 'state', value: 'active' }
                        ]
                    }
                },
                children: [
                    {
                        entityClass: 'Orderadmin\\Integrations\\Entity\\Integration',
                        path: 'entity',
                        component: SourceIntegration
                    },
                    {
                        entityClass: 'Orderadmin\\Integrations\\Entity\\Integration',
                        path: 'entity/:id',
                        component: SourceIntegration
                    }
                ]
            }
        ]
    }
]