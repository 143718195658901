import _ from 'lodash'
import common from './common'
import account from './account'
import acceptance from './acceptance'
import acceptanceItems from './acceptance-items'
import accountRate from './account-rate'
import address from './address'
import assemblingQueue from './assembling-queue'
import automationRules from './automation-rules'
import channels from './channels'
import clientProfile from './client-profile'
import consumption from './consumption'
import country from './country'
import currency from './currency'
import deliveryRequests from './delivery-requests'
import deliveryServices from './delivery-services'
import events from './events'
import expectedOffers from './expected-offers'
import history from './history'
import integrations from './integrations'
import interactions from './interactions'
import weight from './weight'
import warehouse from './warehouse'
import users from './users'
import transactions from './transactions'
import terminalSettings from './terminal-settings'
import templates from './templates'
import invoices from './invoices'
import legalEntities from './legal-entities'
import length from './length'
import task from './task'
import storageItems from './storage-items'
import statistics from './statistics'
import reportsStatistic from './reports-statistic'
import sources from './sources'
import sourceIntegrations from './source-integrations'
import shops from './shops'
import settings from './settings'
import servicePoints from './service-points'
import senders from './senders'
import reports from './reports'
import localNotifications from './local-notifications'
import locality from './locality'
import messages from './messages'
import notificationTasks from './notification-tasks'
import offers from './offers'
import orders from './orders'
import packaging from './packaging'
import payments from './payments'
import rates from './rates'
import rateModifier from './rate-modifier'
import postcodes from './postcodes'
import places from './places'
import placeItems from './place-items'
import pickingSequences from './picking-sequences'
import pickingQueue from './picking-queue'
import phones from './phones'
import storagePlace from './storage-place'
import userRole from './user-role'
import reserve from './reserve'
import helper from './helper'
import documentTemplate from './document-template'
import permission from './permission'
import reportType from './report-type'
import app from './app'
import appAmazon from './app/amazon'
import appEtsy from './app/etsy'
import appShopify from './app/shopify'
import supply from './supply'
import helpSection from './help-section'
import shippingPlans from './shipping-plans'
import inventoryDocuments from "./inventory-documents";
import deliveryServicesMovementService from './delivery-services-movement'

function mergeModules (modules = []) {
  const store = {
    state: {},
    getters: {},
    mutations: {},
    actions: {}
  }

  modules.forEach(module => {
    store.state = { ...store.state, ...(module.state || {}) }
    store.getters = { ...store.getters, ...(module.getters || {}) }
    store.mutations = { ...store.mutations, ...(module.mutations || {}) }
    store.actions = { ...store.actions, ...(module.actions || {}) }
  })

  return store
}

const modules = [
  common,
  account,
  acceptance,
  acceptanceItems,
  accountRate,
  address,
  assemblingQueue,
  automationRules,
  channels,
  clientProfile,
  consumption,
  country,
  currency,
  deliveryRequests,
  deliveryServices,
  events,
  expectedOffers,
  history,
  integrations,
  interactions,
  weight,
  warehouse,
  users,
  transactions,
  terminalSettings,
  templates,
  invoices,
  legalEntities,
  length,
  task,
  storageItems,
  statistics,
  reportsStatistic,
  sources,
  sourceIntegrations,
  shops,
  settings,
  servicePoints,
  senders,
  reports,
  localNotifications,
  locality,
  messages,
  notificationTasks,
  offers,
  orders,
  packaging,
  payments,
  rates,
  rateModifier,
  postcodes,
  places,
  placeItems,
  pickingSequences,
  pickingQueue,
  phones,
  storagePlace,
  userRole,
  reserve,
  helper,
  documentTemplate,
  permission,
  reportType,
  app,
  appAmazon,
  appEtsy,
  appShopify,
  supply,
  helpSection,
  shippingPlans,
  inventoryDocuments,
  deliveryServicesMovementService
]

const defaultState = modules.reduce((acc, module) => {
  return { ...acc, ..._.cloneDeep(module.state || {}) }
}, {})

function resetState (state) {
  const newState = _.cloneDeep(defaultState)

  Object.keys(newState).forEach(key => {
    state[key] = newState[key]
  })
}

modules.push({
  mutations: { resetState }
})

export default mergeModules(modules)
